<template>
  <div class="the_headert w-full h-full">
    <div class="w-full h-full md:flex items-center justify-center tout">
      <div class="md:mr-8 md:text-left text-center">Retour</div>
      <div class="button md:mt-0 mt-2" @click="goTo()">
        <span>Commencer</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data () {
    return {
    }
  },
  methods: {
    goTo () {
      const next = '/' + this.$route.query.product
      if (this.$route.query.product === 'automobile') {
        this.$store.dispatch('position', 0)
      }
      this.$router.push(next)
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/style/sass/variables";

    .the_headert {
        padding: 10px;
        width: 100%;
        height: auto;
        background-color: #fff;
        position: fixed;
        bottom: 0;
      box-shadow: -1px -1px 20px 0px #0000001a;
    }
    div{
      font-size: 18px;
      font-weight: 500;
    }
.button{
  background-color: $base-color;
  border-radius: 10px;
  height: 53px;
  width: 15%;
  color: $white;
  border: none;
}
    @media screen and (max-width: 1024px) and (min-width: 770px){
        .the_headert {
            padding: 15px 0px 15px 0px;
        }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .tout{
        width: 100%;
      }
      .the_headert {
        padding: 10px 0px 10px 0px;
      }
      .button{
        width: 20%;
      }
    }

    @media screen and (max-width: 700px){
        .the_headert {
            padding: 0px 0px 0px 0px;
            font-size: 0.8em;
        }
      .tout{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
      }
      .button{
        width: 100%;
      }
    }

    @media screen and (max-width: 325px){
      .tout{
        width: 80%;
      }
    }
</style>
