<template>
  <div class="home_page h-full">
    <theHeader class="header-shadow" />
    <div class="pt-32">
      <router-view class="men" />
    </div>
     <footMenu class="foo" />
  </div>
</template>

<script>
import theHeader from '@/components/helper/header'
import footMenu from '@/components/helper/footer'

export default {
  name: 'Home',
  components: {
    theHeader,
    footMenu
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.home_page {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
  overflow: hidden;
}
.header-shadow{
  position: fixed;
}

</style>
